<template>
    <div>
        <headerTip></headerTip>
        <div class="head_container">
        
        <div class="head_content">
            <div v-if="$store.state.windowWidth > 1000">
                <!-- <el-tooltip :content="$t('加入最愛')">
                    <div class="join_favorites">
                        <img src="@/assets/images/xw/btn_setFavor.svg" alt="">
                    </div>
                </el-tooltip> -->
                <router-link v-if="$route.path != '/'" class="link_style" to="/">{{ $t('首頁') }}</router-link>
            </div>
            <div v-else @click="$router.push({ path: '/' })" class="logo_wrap">
                <img class="head_logo" :src="logo" alt="">
            </div>

            <div class="head_right">

                <div v-if="$store.state.isLoginStatu">
                    <div class="headIn_R">
                        <div class="memLevel_1" v-if="$store.state.userInfo">{{ $store.state.userInfo.level_name }}
                        </div>

                        <div class="account_info">
                            <el-tooltip
                                :content="$store.state.userInfo ? $store.state.userInfo.username || $store.state.userInfo.phone : ''">
                                <div class="account_name">{{ $t('ID') }}:{{ $store.state.userInfo ?
                                    $store.state.userInfo.username || $store.state.userInfo.phone : '' }}</div>
                            </el-tooltip>

                            <div class="btn_memP" v-if="$store.state.userInfo" @click.stop="togglePlatformBalanceShow">
                                {{ this.$helper.get("rule").currency.code }}
                                {{ numFormat($store.state.userInfo.balance) }}

                                <div v-if="platformBalanceShow" class="inforMDrop" ref="dropdown" @click.stop>
                                    <ul class="inforMDropT">
                                        <li v-for="(item, index) in platformVisited" :key="index">
                                            <div>{{ item.tc_name }}</div>
                                            <div class="nbbPoints">{{
                                                balanceDataState ? Number(item.balance).toFixed(2) : $t('載入中...') }}
                                            </div>
                                        </li>
                                    </ul>
                                    <ul class="inforMDropB">
                                        <li>
                                            <div>{{ $t('總額度') }}</div>
                                            <div class="nbbPoints">{{ balanceDataState ? platformVisited.reduce((total,
                                                item) => total + Number(item.balance),
                                                Number($store.state.userInfo.balance)) : $t('載入中...') }}</div>
                                        </li>
                                        <li class="btn_backAll" v-loading="btn_loading || !balanceDataState"
                                            @click="turnBack">
                                            {{ $t('全部轉回主帳戶') }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>


                        <div class="login_btn_wrap" v-if="$store.state.windowWidth > 1000">
                            <button @click="pageJump('/memberCenter/TRANSFER')" class="memberFlow bg_green">
                                {{ $t('平台轉帳') }}
                            </button>
                            <button @click="pageJump('/memberCenter/DEPOSIT')" class="memberFlow">
                                {{ $t('存款') }}
                            </button>
                            <button @click="pageJump('/memberCenter/WITHDRAWAL')" class="memberFlow">
                                {{ $t('提款') }}
                            </button>
                        </div>

                        <div class="user_btn_wrap" v-if="$store.state.windowWidth > 1000">
                            <el-tooltip :content="$t('會員中心')">
                                <button type="button" @click="pageJump('/memberCenter/USERINFO')"
                                    class="join_favorites">
                                    <img src="@/assets/images/xw/btn_member.png" alt="">
                                </button>
                            </el-tooltip>

                            <el-tooltip :content="$t('個人訊息')">
                                <button @click="pageJump('/xwNotice')" class="join_favorites">
                                    <img src="@/assets/images/xw/btn_mail.png" alt="">
                                </button>
                            </el-tooltip>

                            <el-tooltip :content="$t('登出')">
                                <button type="button" class="join_favorites" :disabled="loginOutLoading"
                                    @click="loginOut">
                                    <img src="@/assets/images/xw/btn_signOut.png" alt="">
                                </button>
                            </el-tooltip>
                        </div>
                        <div v-else class="user_btn_wrap">
                            <router-link to="/xwNotice" class="user_message">
                                <img src="@/assets/images/xw/btn_inforMail_2.svg" alt="">
                            </router-link>
                        </div>

                    </div>
                </div>

                <div v-else>
                    <div class="login_wrap" v-if="$store.state.windowWidth > 1000">

                        <button @click="registerPop = true" type="button" class="register_btn">
                            <span>{{ $t('免費註冊') }}</span>
                        </button>
                        <input type="text" v-model="username" class="login_input" autocomplete="off"
                            :placeholder="$t('賬號/手機號碼')">
                        <div class="passwordBox">
                            <input id="txtPassword" type="password" v-model="password" autocomplete="off"
                                class="login_input" :placeholder="$t('密碼')">
                            <el-tooltip :content="$t('忘記密碼')">
                                <div class="btn_questionMark" @click="openForgetPop">
                                </div>
                            </el-tooltip>
                        </div>
                        <!-- <input type="text" v-model="username" class="login_input" autocomplete="off"
                        :placeholder="$t('驗證碼')"> -->
                        <button type="button" @click="loginIn" :disabled="LoginLoading" class="btn_signIn">
                            <span>{{ $t('登入') }}</span>
                        </button>

                    </div>

                    <div class="login_wrap" v-else>

                        <button type="button" @click="registerPop = true" class="log_btn reg">
                            <span>{{ $t('註冊') }}</span>
                        </button>
                        <button type="button" @click="$store.state.loginVisible = true" class="log_btn log">
                            <span>{{ $t('登入') }}</span>
                        </button>

                    </div>
                </div>

            </div>

        </div>
        <xwModel :visible="registerPop" @close="closeRegisterPop" :title="$t('會員註冊')">
            <template v-slot:content>

                <div class="members_bottom">

                    <div class="member_info">
                        <div class="input_info">
                            <div for="" class="input_title">{{ $t('手機號') }}</div>
                            <div class="input_box">
                                <el-select size="small" v-model="selecQu" :placeholder="$t('请选择')" class="qhClass">
                                    <el-option v-for="item in idc" :key="item.value" :label="item.label"
                                        :value="item.value"></el-option>
                                </el-select>
                                <input v-model="registform.phone" type="text" autocomplete="off" class="phone_input"
                                    :placeholder="$t('輸入你的手機號')">
                            </div>

                        </div>

                        <div class="input_info" v-if="phone_check == 1">
                            <div for="" class="input_title">{{ $t('簡訊驗證碼') }}</div>
                            <input v-model="registform.phoneCode" type="text" autocomplete="off" class="input_box "
                                :placeholder="$t('輸入你的驗證碼')">
                            <el-button class="test_emil" type="primary" :disabled="reg_canClick"
                                @click="account_type == 1 ? sendPhoneCode() : sendEmailCode()">{{ reg_content
                                }}</el-button>
                        </div>

                        <div class="input_info">
                            <div for="" class="input_title">{{ $t('姓名') }}</div>
                            <input v-model="registform.actual_name" type="text" autocomplete="off" class="input_box "
                                :placeholder="$t('請確實填寫資料，以免無法正常充值與托售')">
                        </div>

                        <div class="input_info">
                            <div for="" class="input_title">{{ $t('帳號') }}</div>
                            <input v-model="registform.username" type="text" autocomplete="off" class="input_box "
                            oninput="value = value.replace(/[^A-Za-z\d]/g, '')"  :placeholder="$t('輸入你的帳號')" maxlength="30"style="ime-mode:disabled" />
                        </div>

                        <div class="input_info">
                            <div for="" class="input_title">{{ $t('密碼') }}</div>
                            <input v-model="registform.password" :type="passwordVisible ? 'text' : 'password'"
                                autocomplete="off" class="input_box " :placeholder="$t('輸入你的密碼')">
                            <div class="icon_pw" :class="{ icon_pw_off: passwordVisible }" @click="showPassword"></div>
                        </div>

                        <!-- verificationCode -->
                        <div class="input_info" v-if="code_check == 1 || code_check == 3">
                            <div for="" class="input_title">{{ $t('圖形驗證碼') }}</div>
                            <input v-model="registform.verificationCode" type="text" autocomplete="off"
                                class="input_box " :placeholder="$t('輸入圖形驗證碼')">
                            <el-button class="capt_img" @click="getCaptcha">
                                <el-image class="captchaImageClass" :src="captchaImage" fit="cover"></el-image>
                            </el-button>
                        </div>

                        <div class="input_info">
                            <div for="" class="input_title">{{ $t('line id (選填)') }}</div>
                            <input v-model="registform.line_id" type="text" autocomplete="off" class="input_box "
                                :placeholder="$t('line id')">
                        </div>


                        <div class="input_info">
                            <div for="" class="input_title">{{ $t('電子郵箱 (選填)') }}</div>
                            <input v-model="registform.email" type="text" autocomplete="off" class="input_box "
                                :placeholder="$t('輸入你的電子郵箱')">
                        </div>

                        <div class="input_info">
                            <div for="" class="input_title">{{ $t('推廣碼') }}</div>
                            <input v-model="registform.promotion" :disabled="hasShareCode" type="text" autocomplete="off" class="input_box "
                                :placeholder="$t('推廣碼')">
                        </div>
                    </div>

                    <div class="join_norm">
                        <div class="norm_phonemessage">
                            <div @click="isAgree = !isAgree" class="icon_checkbox" :class="{ on: isAgree }"></div>
                            <div class="check_text">
                                <span>{{ $t('我已年滿18歲，並同意投注相關規範以及') }}</span>
                                <a href="javascript:;" @click="showTC(5)">{{ $t('服務條款') }}</a>
                            </div>
                        </div>
                    </div>
                    <button type="button" @click="sendRegist" class="btn_SentOut tempBtn">{{ $t('確認送出') }}</button>
                </div>
            </template>
        </xwModel>

        <div v-if="$store.state.loginVisible" class="mod_overlay">
            <div class="mod_container" @click.stop>
                <div class="mod_header">
                    <span class="mod_title">{{ $t('會員登入') }}</span>
                    <button class="mod_close" @click="closeLogin"></button>
                </div>
                <div class="mod_body">
                    <div>
                        <div>
                            <input v-model="username" type="text" class="icon_loginID " autocomplete="off"
                                :placeholder="$t('郵箱/手機號碼')">
                        </div>
                    </div>
                    <div>
                        <div style="position: relative;">
                            <input v-model="password" :type="logPasswordVisible ? 'text' : 'password'"
                                class="icon_loginPW" autocomplete="off" :placeholder="$t('密碼')">
                            <div class="icon_log_pw" :class="{ icon_pw_off: logPasswordVisible }"
                                @click="showLogPassword">
                            </div>
                        </div>
                        <div class="loginFG" @click="openForgetPop">{{ $t('忘記密碼？') }}</div>
                    </div>

                    <div class="form_button">
                        <button @click="registerNow" type="button" class="log_btn">{{ $t('立即註冊') }}</button>
                        <button @click="loginIn" type="button" class="log_btn">{{ $t('登入') }}</button>
                    </div>

                </div>
            </div>
        </div>
        <thirdTransferOut v-if="$store.state.userInfo"></thirdTransferOut>

        <!-- 條款彈窗 -->
        <el-dialog :visible.sync="tcVisible" custom-class="custom-dialog">
            <div class="custom-dialog-head" slot="title">
                <div class="header__title">
                    <h1>{{ tcTitle }}</h1>
                </div>
            </div>
            <div class="news__body" v-html="tcContent"></div>
        </el-dialog>
        <forget ref="forget" @back="backForget"></forget>
        
    </div>
    <message style="display: none;"/>
    </div>
   
</template>

<script>
import { game_user_transout_api } from "@/api/cybSports";
import {
    captcha_api,
    login_api,
    login_register_api,
    my_logout_api,
    rule_index_api
} from "@/api/index";
import {
    sendcode_index_api,
    sendcode_mail_api
} from "@/api/user";
import getDevice from "@/common/getDevice";
import forget from "@/components/forget.vue";
import thirdTransferOut from "@/components/thirdTransferOut.vue";
import xwModel from "@/components/xwModel.vue";
import { get_third_balance, get_third_platform } from "@/api/index";
import headerTip from '@/components/headerTip.vue'
import message from "@/components/message.vue";
export default {
    name: 'xwHead',
    components: {
        xwModel,
        thirdTransferOut,
        forget,
        headerTip,
        message
    },
    data() {
        return {
            platformBalanceShow: false,
            logo: process.env.VUE_APP_SITE_LOGO,
            username: '',
            password: '',
            LoginLoading: false,
            registerPop: false,
            passwordVisible: false,
            isAgree: false,
            login_code_key: '',
            captchaImage: "",
            verificationCode: "",
            google_code: '',
            loginOutLoading: false,
            logPasswordVisible: false,
            account_type: this.$helper.get("rule").register_first_account_type == 'phone' ? 1 : 2, // 1手機， 2郵箱
            registform: {
                actual_name: "",
                username: "",
                email: "",
                password: "",
                promotion: "",
                emailCode: "",
                phone: "",
                phoneCode: "",
                verificationCode: "",
                email: '',
                line_id: ''
            },
            selecQu: '',
            tcContent: '',
            tcTitle: '',
            tcVisible: false,
            phone_check: this.$helper.get("rule").phone_check,
       
            reg_canClick: false,
            reg_content: this.$t("發送驗證碼"),
            reg_totalTime: 60,
            phone_bind: this.$helper.get("rule").phone_bind,
            platformVisited: [],
            btn_loading: false,
            balanceDataState: false,
            idc: [],
            selecQu: '',
            code_check:this.$helper.get("rule").code_check,
            hasShareCode:false
        }
    },
    mounted() {
        console.log(this.$helper.get("rule").phone_check,'this.$helper.get("rule").phone_check');
        this.getCaptcha()
        if(this.$route.query.share_code){
            this.registform.promotion = this.$route.query.share_code
            this.hasShareCode = true
        }
        document.addEventListener('click', this.handleClickOutside);
        this.idc = this.$helper.get("idc");
        this.selecQu = this.idc[0]?.value || ''

        if(this.$route.query.modal == "LoginRegistration" || this.$route.query.share_code){
            if(!this.$store.state.isLoginStatu){
                this.registerPop = true
            }
        }
    },
    methods: {

        async turnBack() {
            this.btn_loading = true
            const res = await game_user_transout_api(0)
            if(res.data.code == 1){
                this.$store.commit('setThirdBalance', 0);
                this.updateUserBalance(res.data.balance)
            }
            this.btn_loading = false
            // await this.updateUserInfo();
        },
        togglePlatformBalanceShow() {
            if (this.platformBalanceShow) {
                this.platformBalanceShow = false;
            } else {
                this.fetchThirdBalance()
                this.platformBalanceShow = true
            }
        },
        handleClickOutside(event) {
            if (this.platformBalanceShow && this.$refs.dropdown && !this.$refs.dropdown.contains(event.target)) {
                this.platformBalanceShow = false;
            }
        },
        async fetchThirdBalance() {
            this.balanceDataState = false
            const response = await get_third_balance()
            if (response.data.code == 1) {
                this.platformVisited = response.data.data
                this.balanceDataState = true
            } else {
                this.errorTips(response.data.msg);
                this.balanceDataState = true
            }
        },
        openForgetPop() {
            this.$refs.forget.show();
        },
        backForget() {

        },
        registerNow() {
            this.$store.state.loginVisible = false;
            this.registerPop = true
        },
        closeLogin() {
            this.$store.state.loginVisible = false
        },
        showLogPassword() {
            this.logPasswordVisible = !this.logPasswordVisible;
        },
        pageJump(path) {
            if (this.$store.state.windowWidth > 1000) {
                this.openNewWindowAutoHeightName(`${window.location.origin}${this.$router.mode === 'hash' ? '/#' : ''}${path}`, 'ExampleWindow', 1002, 674)
            } else {
                this.$router.push({ path })
            }
        },
        // 獲取登入、註冊驗證碼
        async getCaptcha() {
            await captcha_api().then(res => {
                if (res && res.data.code == 1) {
                    this.captchaImage = res.data.data.base64;
                    this.login_code_key = res.data.data.key;
                }
            });
        },
        async loginIn() {
            if (this.username && this.password) {
                this.LoginLoading = true
                const params = {
                    account_type: this.account_type,
                    username: this.username,
                    password: this.password,
                    code_key: this.login_code_key,
                    code_value: this.verificationCode,
                    google_code: this.google_code,
                    device_type: getDevice()
                }
                await login_api(params).then(res => {
                    if (res && res.data.code == 1) {
                        this.updateUserInfo();
                        this.$store.state.loginVisible = false;
                        this.$helper.set("tokenInfo", res.data.data);
                        this.$store.commit("$vuexSetUserTokenState", res.data.data.token);
                        this.$store.commit(
                            "$vuexSetUserTokenKeyState",
                            res.data.data.userTokenKey
                        );
                        this.$store.commit("$vuexSetLoginState", true);
                        this.$message({
                            customClass: "successMessage",
                            showClose: false,
                            message: this.$t("登入成功"),
                            center: true,
                            type: "success",
                            duration: "1000"
                        });
                        this.username = ''
                        this.password = ''
                    } else {
                        this.$xwDialog.open({
                            title: this.$t('訊息'),
                            content: this.$t(res.data.msg),
                            hasClose: false,
                            onClose: () => {
                            }
                        });
                    }
                    this.LoginLoading = false;
                });
            } else {
                this.$xwDialog.open({
                    title: this.$t('訊息'),
                    content: this.$t('賬號或密碼不能為空！'),
                    hasClose: false
                });
            }
        },

        async sendRegist() {
            
            if (this.registform.phone == "") {
                this.errorTips('輸入你的手機號碼');
                return false;
            }

            if (this.registform.actual_name == "") {
                this.errorTips('輸入你的姓名');
                return false;
            }

            if (this.registform.username == "") {
                this.errorTips('輸入你的账号');
                return false;
            }
            if (this.registform.username) {
                const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]+$/;
                if(!regex.test(this.registform.username)){
                    this.errorTips('账号應由字母和數字組成');
                    return false;
                }
            }

            if (this.registform.phone == this.registform.username) {
                this.errorTips('賬號和手機號不能一致');
                return false;
            }

            if (
                this.registform.verificationCode == "" &&
                (this.code_check == 1 || this.code_check == 3)
            ) {
                this.errorTips('輸入圖形驗證碼');
                return false;
            }

            if (this.registform.password == "") {
                this.errorTips('輸入你的密碼');
                return false;
            }
            

            this.registerLoading = true;
            let param = {
                account_type: this.account_type, // 2郵箱 1手機號碼
                check_type: this.registform.phoneCode ? this.account_type : 0, //1為手機號，2郵箱 0 是沒有驗證碼
                username: this.registform.username,
                password: this.registform.password,
                code_key: this.login_code_key,
                code_value: this.registform.verificationCode,
                actual_name: this.registform.actual_name,
                areacode: this.selecQu,
                code: this.registform.phoneCode,
                rec_code: this.registform.promotion,
                phone: this.registform.phone,
                mail: this.registform.email,
                acid: localStorage.getItem('channelAcid'),
                line_id: this.registform.line_id,
                // register_type: this.$route.query.register_type,
                device_type: getDevice()
            };
            await login_register_api(param).then(res => {
                if (res && res.data.code == 1) {
                    this.closeLoginReg();
                    this.$helper.set("tokenInfo", res.data.data);
                    this.$store.commit("$vuexSetUserTokenState", res.data.data.token);
                    this.$store.commit("$vuexSetUserTokenKeyState", res.data.data.userTokenKey);
                    this.$store.state.loginVisible = false;
                    this.updateUserInfo();
                    this.$store.commit("$vuexSetLoginState", true);
                    //上報註冊, register 是固定值
                    this.$buryingPoint.register()
                    this.successTips('註冊成功', {
                        showClose: false,
                        duration: "1000",
                        onClose: () => {
                            this.registerPop = false;
                        }
                    });
                } else {
                    this.errorTips(res.data.msg, {
                        showClose: false,
                        duration: "2000",
                        onClose: () => {
                            this.getCaptcha();
                        }
                    });
                }
            });
            this.registerLoading = false;
        },

        closeRegisterPop() {
            console.log('aaaa');
            this.$xwDialog.open({
                title: this.$t('訊息'),
                content: this.$t('您尚未完成註冊，是否繼續'),
                hasClose: true,
                onClose: () => {
                    this.registerPop = false;
                }
            });
        },
        showPassword() {
            this.passwordVisible = !this.passwordVisible;
        },

        async loginOut() {
            this.loginOutLoading = true;
            this.$helper.rm("userInfo");
            await my_logout_api().then(res => {
                if (res && res.data.code == 1) {
                    this.userShow = false;
                    this.$helper.rm("tokenInfo");
                    this.$helper.rm("userInfo");
                    this.$store.commit("$vuexSetLoginState", false);
                    this.$store.commit("$vuexUserInfo", null);
                    this.$message({
                        customClass: "successMessage",
                        showClose: false,
                        message: this.$t("已成功退出當前賬號"),
                        center: true,
                        type: "success",
                        duration: "1000",
                        onClose: () => {
                            this.goUrl('/');
                        }
                    });
                } else {
                    this.errorTips("失敗");
                }
                this.loginOutLoading = false;
                this.$helper.rm("tipStorage");
            });
        },

        showTC(type) {
            this.tcContent = "";
            this.tcTitle = "";
            const key = "tc_content_" + type + "_" + this.$helper.getLang();
            if (this.$helper.get(key)) {
                this.tcContent = this.$helper.get(key).content;
                this.tcTitle = this.$helper.get(key).title;
                this.tcVisible = true;
                return;
            }
            this.getRule(type);
        },
        getRule(type) {
            const self = this;
            const key = "tc_content_" + type + "_" + this.$helper.getLang();
            rule_index_api(type).then(response => {
                if (response && response.data.code == 1) {
                    const data = response.data.data;
                    if (data) {
                        self.$helper.set(key, data);
                        self.showTC(type);
                    } else {
                        this.errorTips("沒有內容");
                    }
                }
            });
        },
        // 獲取手機驗證碼
        async sendPhoneCode() {
            if (this.reg_canClick) return;
            if (this.registform.phone == "") {
                this.errorTips("手機不能為空");
                return;
            }
            await sendcode_index_api({
                qh: this.selecQu,
                phone: this.registform.phone
            }).then(res => {
                if (res && res.data.code == 1) {
                    this.reg_canClick = true;
                    this.reg_content = this.reg_totalTime + "s";
                    let clock = window.setInterval(() => {
                        this.reg_totalTime--;
                        this.reg_content = this.reg_totalTime + "s";
                        if (this.reg_totalTime < 0) {
                            window.clearInterval(clock);
                            this.reg_content = this.$t("重新送出");
                            this.reg_totalTime = 60;
                            this.reg_canClick = false;
                        }
                    }, 1000);
                    this.successTips(res.data.msg);
                } else {
                    this.errorTips(res.data.msg);
                }
            });
        },
        sendEmailCode() {
            if (this.reg_canClick) return;
            if (this.registform.username == "") {
                this.errorTips("郵箱不能為空");
                return;
            }
            const mailReg = /^([a-zA-Z0-9_.-]+)@([a-zA-Z0-9_-]+).([a-zA-Z0-9_-]+)(.[a-zA-Z0-9_-]+)*$/;
            if (mailReg.test(this.registform.username)) {
                this.getMailCode();
            } else {
                this.errorTips("請輸入正確的郵箱格式");
                return;
            }
        },
        // 送出郵箱驗證碼
        async getMailCode() {
            await sendcode_mail_api({
                email: this.registform.username
            }).then(res => {
                if (res && res.data.code == 1) {
                    this.reg_canClick = true;
                    this.reg_content = this.reg_totalTime + "s";
                    let clock = window.setInterval(() => {
                        this.reg_totalTime--;
                        this.reg_content = this.reg_totalTime + "s";
                        if (this.reg_totalTime < 0) {
                            window.clearInterval(clock);
                            this.reg_content = this.$t("重新送出");
                            this.reg_totalTime = 60;
                            this.reg_canClick = false;
                        }
                    }, 1000);
                    this.successTips(res.data.msg);
                } else {
                    this.errorTips(res.data.msg);
                }
            });
        },
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClickOutside);
    },
}

</script>

<style lang="less" scoped>
.head_container {
    background: #3f9eff;
    height: 48px;
    padding: 0 20px;

    @media (max-width:1000px) {
        background: #fff;
        padding: 0 2%;
        height: 70px;
    }
}

.head_content {
    max-width: 1000px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    padding: 5px;
}

.head_logo {
    height: 100%;
    max-width: 100%;
    width: auto;
    object-fit: cover;
    cursor: pointer;
}

.join_favorites {
    opacity: .6;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }
}

.head_right {
    display: flex;
    align-items: center;
}

.login_wrap {
    display: flex;
    align-items: center;
    gap: 12px;

    @media (max-width:1000px) {
        gap: 8px;
    }
}

.register_btn {
    background: #379277 url('../assets/images/xw/icon_register.svg') no-repeat 6px 50%;
    background-size: 14px;
    color: #fff;
    height: 24px;
    line-height: 23px;
    border-radius: 5px;
    padding: 0 8px 0 25px;
    float: left;
    min-width: 90px;
    box-sizing: border-box;
    font-size: 14px;

    &:hover {
        background-color: #ffae00;
    }
}

.login_input {
    background-color: #aacff5;
    border-radius: 5px;
    color: #000;
    width: 115px;
    height: 24px;
    padding: 0 8px;
    font-size: 14px;
    // text-transform: uppercase;
    outline: none;
    border: 0;
    user-select: initial;
}

.passwordBox {
    position: relative;
    background-color: #0f6146;
    border-radius: 5px;
}

.btn_questionMark {
    background: url('../assets/images/xw/btn_questionMark.svg') no-repeat center;
    background-size: 15px;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 0;
    right: 0;
    opacity: .6;
    cursor: pointer;
}

.btn_signIn {
    height: 24px;
    line-height: 23px;
    padding: 0 10px;
    border-radius: 5px;
    background-color: #bd2021;
    color: #fff;
    min-width: 48px;
    font-size: 14px;
}

.log_btn {
    text-decoration: none;
    cursor: pointer;
    font-size: 14px;
    padding-left: 15px;
    background-position: 6px center;
    background-repeat: no-repeat;
    display: flex;
    align-self: center;
    justify-content: center;
    border-radius: 100px;
    color: #fff;
    line-height: 23px;
    width: 80px;
    box-sizing: border-box;
    background-size: 15px auto;
    font-weight: normal;
}

.log_btn.reg {
    background-color: #3f9eff;
    background-image: url('../assets/images/xw/btn_join2.svg');
}

.log_btn.log {
    background-color: #bd2021;
    background-image: url('../assets/images/xw/btn_login2.svg');
}

.members_bottom {
    @media (max-width:1000px) {
        padding-bottom: 30px;
    }
}

.member_info {
    width: 385px;
    margin: 0 auto;
    padding: 20px 0;
    padding-right: 3px;
    border-bottom: 1px solid #ddd;

    @media (max-width:1000px) {
        width: 100%;
        border: none;
    }
}

.input_info {
    margin-top: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width:1000px) {
        flex-direction: column;
        border-bottom: 1px solid #ddd;
    }
}

.input_title {
    font-size: 14px;
    color: #333;
    line-height: 38px;

    @media (max-width:1000px) {
        width: 100%;
        text-align: left;
    }
}

.input_box {
    width: 270px;
    height: 38px;
    padding: 0 8px;
    box-sizing: border-box;
    color: #000;
    border: 1px solid #e5e5e5;
    outline: none;
    display: flex;

    @media (max-width:1000px) {
        display: flex;
        width: 100%;
        height: 38px;
        border-color: transparent;
        border: none;
    }
}

.uppercase {
    text-transform: uppercase;
}

.icon_pw {
    position: absolute;
    top: 10px;
    right: 5px;
    width: 18px;
    height: 18px;
    background: url('../assets/images/xw/icon_pwON.svg') no-repeat top center;
    background-size: 100%;
    opacity: .8;
    cursor: pointer;

    @media (max-width:1000px) {
        top: auto;
        bottom: 10px;
    }
}

.test_emil {
    position: absolute;
    right: 4px;
    bottom: 50%;
    transform: translateY(50%);
    border-radius: 5px;
    padding: 8px 14px !important;

    @media (max-width:1000px) {
        transform: none;
        bottom: 8px;
    }
}

.capt_img {
    position: absolute;
    right: 4px;
    bottom: 50%;
    transform: translateY(50%);
    border-radius: 5px;
    padding: 0 !important;

    img {}

    -webkit-tap-highlight-color: transparent;

    @media (max-width:1000px) {
        transform: none;
        bottom: 8px;
    }
}

.icon_log_pw {
    position: absolute;
    top: 14px;
    right: 10px;
    width: 18px;
    height: 18px;
    background: url('../assets/images/xw/icon_pwON.svg') no-repeat top center;
    background-size: 100%;
    opacity: .8;
    cursor: pointer;
}

.icon_loginPW {
    background-image: url('../assets/images/xw/icon_loginPW.svg');
}

.icon_pw_off {
    background: url('../assets/images/xw/icon_pwOFF.svg') no-repeat top center !important;
    background-size: 100% !important;
    opacity: .8;
}

.join_norm {
    width: 385px;
    margin: 0 auto;
    padding: 20px 0;
    padding-right: 3px;

    @media (max-width:1000px) {
        width: 100%;
    }
}

.norm_phonemessage {
    display: flex;
    align-items: flex-start;
    gap: 10px;
}

.icon_checkbox {
    width: 25px;
    height: 25px;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    background-color: #fff;
    float: left;
    cursor: pointer;
}

.icon_checkbox.on {
    background: url('../assets/images/xw/icon_check.svg') no-repeat center;
    background-color: #e5e5e5;
    background-size: 80%;
}

.check_text {
    line-height: 25px;
    font-size: 14px;

    a {
        color: #3a07f2;
    }
}

.btn_SentOut {
    width: 385px;
    height: 50px;
    display: block;
    margin: 5px auto 0;
    color: #fff;
    font-size: 16px;
    border: 0;
    background-size: 6%;
    background-color: #5a88d5;
    cursor: pointer;

    @media (max-width:1000px) {
        width: 100%;
        border-radius: 10px;
    }
}

.headIn_R {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 15px;
    gap: 12px;

}

.memLevel_1 {
    color: #d4a34c;

    @media (max-width:1000px) {
        display: none;
    }
}

.account_info {
    display: flex;
    align-items: center;
    gap: 4px;

    @media (max-width:1000px) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.account_name {
    max-width: 96px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width:1000px) {
        color: #7cb2b5;
    }
}

.btn_memP {
    position: relative;
    color: #ffde00;
    font-size: 18px;
    padding-right: 15px;
    cursor: pointer;

    &::after {
        content: '';
        background: url('../assets/images/xw/icon_arrow.svg') no-repeat center;
        background-size: 100%;
        width: 10px;
        height: 10px;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto 0;
        opacity: .5;

        @media (max-width:1000px) {
            background: url('../assets/images/xw/icon_arrowGray.svg') no-repeat center;
            background-size: 100%;
        }
    }
}

.login_btn_wrap {
    display: flex;
    align-items: center;
    gap: 8px;

    @media (max-width:1000px) {
        display: none;
    }
}

.user_btn_wrap {
    display: flex;
    align-items: center;
    gap: 12px;
}

.memberFlow {
    position: relative;
    display: flex;
    align-items: center;
    height: 26px;
    min-width: 65px;
    border-radius: 3px;
    font-size: 14px;
    padding: 0 15px;
    line-height: 26px;
    background-color: #f39800;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none !important;
    color: #fff;
}

.bg_green {
    background-color: #279472;
}

.user_message {
    width: 20px;
    height: 20px;
}

.mod_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: var(--vh);
    z-index: 999;
}

.mod_container {
    background: transparent;
    border-radius: 6px;
    overflow: hidden;
    width: 500px;

    @media (max-width:1000px) {
        width: 88%;
        max-width: 500px;
    }
}

.mod_header {
    color: #fff;
    height: 70px;
    background-color: #3f9eff;
    text-align: center;
    font-size: 22px;
    line-height: 70px;
    position: relative;

    @media (max-width:1000px) {
        height: 45px;
        font-weight: bold;
        position: relative;
        line-height: 45px;
        font-size: 16px;
    }
}

.mod_title {
    margin: 0;
}

.mod_close {
    width: 50px;
    height: 50px;
    background: url('../assets/images/xw/btn_close.svg') no-repeat center;
    background-size: 50%;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    opacity: .5;

    &:hover {
        opacity: 1;
    }

    @media (max-width:1000px) {
        width: 40px;
        height: 40px;
        opacity: 1;
    }
}

.mod_body {
    padding: 0;
    font-size: 15px;
    line-height: 1.3;
    text-align: center;
    padding-bottom: 15px;
    color: #333;
    position: relative;
    word-break: break-word !important;
    background: #f5f5f5;
    padding: 33px 22px 22px 22px !important;

    @media (max-width:1000px) {
        max-height: calc(var(--vh) - 100px);
        overflow-y: auto;
    }
}

.icon_loginID,
.icon_loginPW {
    width: 100%;
    padding-left: 15% !important;
    margin-bottom: 33px;
    background-repeat: no-repeat;
    background-position: 3.5% center;
    background-size: auto 55%;
    height: 45px;
    outline: none;
    border: 1px solid #ddd;
}

.icon_loginID {
    background-image: url('../assets/images/xw/icon_loginID.svg') !important;
}

.pwON {
    position: relative;
}

.pwON.login {
    display: flex;
    border-radius: 3px;
    margin-bottom: 33px;
}

.loginFG {
    text-align: right;
    color: #3f9eff;
    font-size: 14px;
    margin-top: -18px;
    margin-bottom: 5px;
    position: relative;
    z-index: 1;
    width: fit-content;
    float: right;
}

.form_button {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;

    .log_btn {
        flex: 1;
        width: 100%;
        color: #fff;
        background-color: #3f9eff;
        height: 45px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:last-child {
            background-color: #bd2021;
        }
    }
}

.news__body {
    color: #000 !important;
}

.inforMDrop {
    position: absolute;
    background-color: #49484b;
    height: 100%;
    min-height: 260px;
    max-height: 522px;
    width: 65vw;
    max-width: 270px;
    border-radius: 5px;
    right: -20px;
    top: 32px;
    font-size: .8em;
    z-index: 200;
    -webkit-overflow-scrolling: touch;
    text-align: initial;
    font-weight: normal;
    color: #fff;
    user-select: none;

    &::before {
        content: "";
        border-style: solid;
        border-width: 0 6px 6px 6px;
        border-color: transparent transparent #49484b transparent;
        position: absolute;
        right: 20px;
        top: -6px;
    }
}

.inforMDropT {
    overflow-y: auto;
    height: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    padding-bottom: 76px;
    list-style: none;
}

.inforMDropT li,
.inforMDropB li {
    margin: auto;
    display: table;
    width: 85%;
    height: 30px;
    position: relative;

    &:not(.btn_backAll)::before {
        content: '';
        position: absolute;
        border-right: 1px solid #59585b;
        top: 0;
        bottom: 0;
        left: 35%;
        height: 35%;
        margin: auto;
    }
}

.inforMDropT li {
    &:not(.btn_backAll)::after {
        content: '';
        position: absolute;
        border-bottom: 1px solid #59585b;
        bottom: 0;
        left: 0;
        right: 0;
    }

}

.inforMDropT li:first-child div {
    padding-top: 10px;
    padding-bottom: 10px;
}

.inforMDropT div:first-child,
.inforMDropB div:first-child {
    width: 40%;
}

.inforMDropT div,
.inforMDropB div {
    display: table-cell;
    vertical-align: middle;
    position: relative;
    line-height: 1.1em;
}

.inforMDropT li:first-child div {
    padding-top: 10px;
    padding-bottom: 10px;
}

.inforMDropT div:nth-child(2),
.inforMDropB div:nth-child(2) {
    width: 60%;
    color: #ffde00;
    padding-left: 5%;
}

.inforMDropT div,
.inforMDropB div {
    display: table-cell;
    vertical-align: middle;
    position: relative;
    line-height: 1.1em;
}

.inforMDropB .btn_backAll {
    color: #fff;
    width: 90%;
    background-color: #4bab9a;
    border-radius: 2.5em;
    line-height: 2.5em;
    height: auto !important;
    margin-bottom: 10px;
    cursor: pointer;
    text-align: center;
}

.inforMDropB {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #272727;
    border-radius: 0 0 5px 5px;
}

.link_style {
    color: #fff;
    text-decoration: none;
}

.qhClass {
    height: 100%;
    max-width: 90px;
    padding-top: 2px;
    margin-left: -16px;
}

.phone_input {
    border: none;
    outline: none;
}

.qhClass /deep/ .el-input__inner {
    margin-left: 6px !important;
    border: 0 !important;
    background: none !important;
    display: flex;
    align-items: center;
}
</style>