<template>
    <div v-if="visible" class="mod_overlay">
        <div class="mod_container" @click.stop>
            <div class="mod_header">
                <span class="mod_title">{{ title }}</span>
                <button class="mod_close" @click="close"></button>
            </div>
            <div class="mod_body">
                <slot name="content">

                </slot>
            </div>
            <div>
                <slot name="footer">
                    <button type="button">Close</button>
                </slot>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        title: {
            type: String,
            default: '標題'
        },
        visible: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
        };
    },
    methods: {
        close() {
            this.$emit('close');
        }
    }
}
</script>

<style lang="less" scoped>
.mod_overlay {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: var(--vh);
    z-index: 10000;
}

.mod_container {
    background: white;
    border-radius: 8px;
    overflow: hidden;
    width: 500px;
    padding-bottom: 16px;
    box-sizing: border-box;

    @media (max-width:1000px) {
        max-width: 100vw;
        width: 100vw;
        height: var(--vh);
        border-radius: 0;
    }
}

.mod_header {
    color: #fff;
    height: 70px;
    background-color: #3f9eff;
    text-align: center;
    font-size: 22px;
    line-height: 70px;
    position: relative;

    @media (max-width:1000px) {
        position: relative;
        width: 100vw;
        height: 40px;
        font-weight: bold;
        line-height: 40px;
        font-size: 16px;
    }
}

.mod_title {
    margin: 0;
}

.mod_close {
    width: 50px;
    height: 50px;
    background: url('../assets/images/xw/btn_close.svg') no-repeat center;
    background-size: 50%;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    opacity: .5;
    &:hover {
        opacity: 1;
    }

    @media (max-width:1000px) {
        background: url('../assets/images/xw/btn_back.svg') no-repeat center !important;
        background-size: auto 40% !important;
        width: 40px;
        height: 40px;
        left: 0;
        opacity: 1;
    }
}

.mod_body {
    padding: 0;
    font-size: 15px;
    line-height: 1.3;
    text-align: center;
    margin: 3px 4%;
    padding-bottom: 15px;
    color: #333;
    position: relative;
    word-break: break-word !important;
    @media (max-width:1000px) {
        max-height: calc(var(--vh) - 100px);
        overflow-y: auto;
    }
}
</style>